@import "helpers/variables";
@import "helpers/mixins";
@import "vendor/normalize";
@import "components/modal";
@import "components/modal-popup";
@import "components/dlg-cross-outside-inline";
@import "components/payment-frame";
@import "https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&amp;subset=cyrillic";

html {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  text-size-adjust: 100%; /* 2 */
  color: #000;
}

.container-content {
  padding-top: 36px;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding: 0 10px;
  max-width: 1240px;

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  h1 {
    display: block;
    margin: 0;
    margin-bottom: 2px;
    padding: 10px 0;
    font-weight: 400;
    font-size: 30px;
    text-align: center;
  }
}

.v-content-main-task {
  background-color: #fff;

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  .v-pupil-block-title {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 24px;
  }
}

.v-sum-oplata-wrapper {
  text-align: center;

  .v-sum-oplata-page {
    margin: 0 0 50px;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    color: #248abc;

    .v-sum {
      position: relative;
      display: inline-block;
      margin-top: 0;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
    }
  }

  .v-grey-block {
    min-height: 248px;
    border: 2px solid #ddd;
    border-radius: 6px;
    background-color: #f5f5f5;
    cursor: default;

    .v-main-button {
      max-height: 64px;

      i {
        display: inline-block;
        margin-right: 8px;
        width: 35px;
        height: 28px;
        vertical-align: middle;
      }

      &.v-blue-main {
        i {
          display: inline-block;
          margin-right: 8px;
          width: 28px;
          height: 32px;
          vertical-align: middle;
        }
      }
    }

    .v-blue-main {
      float: none;
    }
  }

  .blue-btn {
    display: inline-block;
    margin: 10px 0;
    padding: 10px 15px;
    border-radius: 4px;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    background-color: #0e6691;
    transition: all 200ms linear;

    &:hover {
      text-decoration: none;
      background-color: #248abc;
    }
  }

  .v-grey-btn {
    color: #818181;
    background-color: #ddd;

    &:hover {
      text-decoration: none;
      background-color: #cbc8c8;
    }
  }

  span {
    display: block;
    margin-top: 10px;
    font-size: 18px;
    line-height: 22px;
  }

  h1 {
    text-align: left;
  }
}

.col-6 {
  float: left;
  margin-right: 1.69492%;
  width: 49.15254%;

  &.col-last {
    float: right;
    margin-right: 0;
  }
}

.v-main-button {
  position: relative;
  z-index: 999;
  display: block;
  margin: 35px auto;
  padding: 18px 26px;
  max-width: 320px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 22px;
  line-height: 100%;
  text-align: center;
  color: #fff;
  background-size: 304px;
  background-repeat: repeat;
  background-color: #5cbb73;

  &:hover {
    text-decoration: none;
    color: #fff;
    animation: cssProgressActive 2s linear infinite;
  }

  &.load-button {
    text-decoration: none;
    color: #fff;
    animation: cssProgressActive 2s linear infinite;
  }

  &.v-blue-main {
    float: left;
    background-color: #248abc;
  }

  &.v-orange-main {
    background-color: #ffbf00;
  }

  &.v-next {
    float: right;
  }

  &.v-m-b {
    margin-top: 20px;
    margin-bottom: 0;
  }

  &.red-button {
    background-color: #ea3c3c !important;
  }

  &.button-task {
    display: inline-block;
    margin: 18px 0 0;
    padding: 10px 60px 10px 19px;
    font-size: 18px;
    text-align: left;
    color: #008684;
    background-color: #fff;

    &::before {
      content: "";
      position: absolute;
      right: 21px;
      top: 29px;
      display: block;
      width: 26px;
      height: 2px;
      background-color: #008684;
      transition: all 0.6s ease-out;
    }

    &::after {
      content: "";
      position: absolute;
      right: 34px;
      top: 24px;
      display: block;
      width: 0;
      border: 6px solid transparent;
      border-left: 7px solid #008684;
      transition: all 0.6s ease-out;
    }

    &:hover {
      &::after {
        right: 10px;
      }
    }
  }

  &.back-button {
    display: inline-block;
    margin: 0 auto;
    padding: 18px 24px 18px 56px;
    max-width: inherit;

    &::before {
      content: "";
      position: absolute;
      left: 21px;
      top: 29px;
      display: block;
      width: 26px;
      height: 2px;
      background-color: #fff;
      transition: all 0.6s ease-out;
    }

    &::after {
      content: "";
      position: absolute;
      left: 35px;
      right: 0;
      top: 24px;
      display: block;
      width: 0;
      border: 6px solid transparent;
      border-right: 7px solid #fff;
      transition: all 0.6s ease-out;
    }

    &:hover {
      &::after {
        left: 12px;
      }
    }
  }

  &.v-transpar {
    border: 1px solid #fff;
    color: #248abc;
    background-color: transparent;

    &::before {
      background-color: #248abc;
    }

    &:hover {
      border: 1px solid #248abc;
    }

    &.t-left {
      &::after {
        border-right: 7px solid #248abc;
      }
    }

    &.t-right {
      padding-right: 75px;

      &::before {
        content: "";
        position: absolute;
        right: 37px;
        top: 29px;
        display: block;
        width: 26px;
        height: 2px;
        background-color: #248abc;
        transition: all 0.6s ease-out;
      }

      &::after {
        content: "";
        position: absolute;
        right: 50px;
        top: 24px;
        display: block;
        width: 0;
        border: 6px solid transparent;
        border-left: 7px solid #248abc;
        transition: all 0.6s ease-out;
      }

      &:hover {
        &::after {
          right: 25px;
        }
      }
    }
  }

  &.v-fln {
    float: none;
  }
}

.col-1 {
  float: left;
  width: 78%;
}

.col-2 {
  float: right;
  width: 21%;
}

.v-right-block {
  padding: 20px 0 0;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  text-align: center;

  p {
    margin: 10px 0;
    padding: 0 20px;
  }

  .pay-block {
    margin-top: 20px;
    padding: 20px;
    font-size: 22px;
    background-color: #e2f8ff;

    .pay-count {
      margin-top: 20px;
      padding: 10px;
      border: 2px solid #5cbb73;
      border-radius: 6px;
      font-weight: 700;
      font-size: 26px;
      text-transform: uppercase;
      color: #5cbb73;
    }
  }
}

.v-form-code {
  label {
    display: block;
    font-size: 18px;
  }

  input[type="text"] {
    margin: 20px 0;
    padding-left: 10px;
    width: 100%;
    min-height: 58px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 22px;
  }

  input[type="submit"] {
    position: relative;
    z-index: 999;
    display: block;
    padding: 18px 26px;
    max-width: 320px;
    outline: none;
    border: none;
    border-radius: 6px;
    font-weight: 700;
    font-size: 22px;
    line-height: 100%;
    text-align: center;
    color: #fff;
    background-color: #5cbb73;
  }
}

.v-pupils-list-wrap {
  margin: auto;
  max-width: 800px;

  ul {
    li {
      display: table;
      margin-bottom: 10px;
      padding-bottom: 10px;
      width: 100%;
      border-bottom: 1px solid #ccc;

      &:last-child {
        border-bottom: none;
      }

      .icon,
      .v-pupils-list-text,
      .v-pupils-sum {
        display: table-cell;
        vertical-align: middle;
      }

      .icon {
        input[type="checkbox"] {
          display: none;
        }

        label {
          display: inline-block;
          width: 40px;
          height: 40px;
          vertical-align: middle;

          &::before {
            box-sizing: border-box;
            content: "";
            position: absolute;
            display: block;
            width: 40px;
            height: 40px;
            border: 2px solid #5cbb73;
            cursor: pointer;
          }
        }

        input[type="checkbox"]:checked + label::before {
          background-image: url("../img/tick.svg");
          background-position: 50%;
          background-size: 90% 90%;
          background-repeat: no-repeat;
        }
      }

      .v-pupils-sum {
        font-weight: 700;
        font-size: 26px;
        text-align: right;
        color: #5cbb73;
      }

      .v-pupils-list-text {
        .v-predmet {
          font-size: 20px;
        }

        .v-teacher {
          font-style: italic;
          font-size: 18px;
          color: #55595b;
        }
      }
    }
  }
}

.v-pupil-name {
  margin: 20px 0;
  font-size: 18px;
  text-align: center;

  b {
    font-weight: 300;
    color: #797979;
  }

  strong {
    color: #b91414;
  }

  a {
    text-decoration: underline;
    color: #315cab;

    &:hover {
      text-decoration: none;
    }
  }
}

.a-alert-block {
  clear: both;
  margin: 16px auto;
  padding: 10px;
  max-width: 800px;
  border: 1px solid #f1db7e;
  border-radius: 4px;
  background-color: #fcffe7;
}

.v-contact-link {
  clear: both;
  margin-top: 40px;
  font-size: 20px;
  text-align: center;

  a {
    text-decoration: underline;
    color: #5cbb73;

    &:hover {
      text-decoration: none;
    }
  }
}

.v-logo {
  display: block;
  margin: auto;
  width: 300px;
  height: 50px;
  background-image: url("../img/logo.svg");
  background-repeat: no-repeat;
}

.help-block {
  margin: 0 0 20px;
  color: #f00;
}
