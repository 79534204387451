.modal-popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 30%;
  z-index: 99999999;
  margin: 0 auto 10px;
  padding: 20px;
  height: auto;
  max-width: 90%;
  border-radius: 4px;
  box-shadow: 0 0 15px -5px #ebebeb;
  line-height: 1.25;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);

  &--error {
    background-color: rgba(165, 18, 6, 0.85);

    & .modal-popup__close:hover {
      background-color: #892d25;
    }

    .errorSummary,
    .errorMessage {
      color: #fff;
    }
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1px 8px 3px;
    border-radius: 4px;
    font-weight: 900;
    cursor: pointer;

    &:hover {
      background-color: #7c7c7c;
    }
  }
}
