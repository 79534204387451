@import "../helpers/mixins";
@import "../helpers/variables";

.dlg-cross-outside {
  position: relative;
  padding: 40px;

  @include media($md) {
    padding: 24px 8px;
  }

  &__cross {
    position: absolute;
    right: -28px;
    top: 4px;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;

    @include media($md) {
      right: 4px;
    }
  }
}
